import Box from '@material-ui/core/Box';
import ReactHtmlParser from 'html-react-parser';
import React from 'react';

import { RenderingField } from '@breathelife/questionnaire-engine';

import { BaseFieldProps } from '../FieldGenerator/FieldGenerator';
import { Field } from './Field';
import { QuestionContainer } from './Styles';

export type ProSummaryQuestionProps = BaseFieldProps;

export function ProSummaryQuestion(props: ProSummaryQuestionProps): React.ReactElement | null {
  const { currency, locale, question } = props;

  return (
    <QuestionContainer mt={2}>
      {question.title && (
        <Box fontSize={14} fontWeight={600}>
          {ReactHtmlParser(question.title)}
        </Box>
      )}
      {question.text && (
        <Box fontSize={14} fontWeight={600}>
          {ReactHtmlParser(question.text)}
        </Box>
      )}
      {question.fields.map((field: RenderingField) => (
        <Field key={field.id} currency={currency} field={field} locale={locale} />
      ))}
    </QuestionContainer>
  );
}
