import Box from '@material-ui/core/Box';
import IconButton from '@material-ui/core/IconButton';
import styled from 'styled-components';

import { FullColorGradient } from '@breathelife/types';

export const QuestionContainer = styled(Box)`
  &:last-child {
    margin-bottom: 0;
  }
`;

export const QuestionTitle = styled(Box)`
  font-size: 13px;
  line-height: 1.3;
  color: ${(props) => props.theme.colors.grey[90]};

  // Nested HTML
  p {
    margin-bottom: 8px;
  }

  ul,
  ol {
    margin-left: 16px;
    padding-left: 8px;
    margin-top: 16px;
    margin-bottom: 16px;

    li {
      margin-bottom: 4px;
    }
  }

  ul {
    list-style: disc;
  }
`;

export const FieldContainer = styled(Box)`
  color: ${(props) => props.theme.colors.grey[90]};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const FieldLabel = styled(Box)<{ grey?: keyof FullColorGradient }>`
  color: ${(props) => props.theme.colors.grey[props.grey ?? 70]};

  ul,
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  li {
    margin-bottom: 8px;
  }
`;

export const Button = styled(IconButton)`
  && {
    padding: 0;
    &:hover {
      background-color: transparent;
    }
  }
`;
