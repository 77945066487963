import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import styled, { css } from 'styled-components';

import { FieldSizes } from '@breathelife/types';

type FieldContainerProps = {
  fieldSize: FieldSizes;
};

export const FieldContainer = styled.div<FieldContainerProps>`
  display: inline-block;
  vertical-align: top;
  font-size: 13px;
  min-height: 32px;
  padding-right: 35px;
  margin: 8px 0;
  width: ${(props) => {
    switch (props.fieldSize) {
      case FieldSizes.third:
        return '33%';
      case FieldSizes.twoThirds:
        return '67%';
      case FieldSizes.full:
        return '100%';
    }
  }};
`;

export const FieldWrapper = styled.div`
  margin-top: 5px;
`;

export const CheckboxGroupWrap = styled.div`
  margin-left: -12px;
`;

export const CheckboxItem = styled.div`
  display: inline-block;
  margin-left: 12px;
`;

const checkboxStyle = css`
  height: 15px !important;
  width: 15px !important;
  vertical-align: top;
  margin: 2px 3px 0 -1px;
`;

export const CheckedIcon = styled(CheckBoxIcon)`
  ${checkboxStyle}
`;

export const UncheckedIcon = styled(CheckBoxOutlineBlankIcon)`
  ${checkboxStyle}
`;

export const Label = styled.div`
  &&& {
    font-size: 10px;
    line-height: 12px;
    color: ${(props) => props.theme.colors.grey[80]};
  }
`;

type AvoidBreakInsideProps = {
  avoidBreakInside?: boolean;
};

export const QuestionContainer = styled.div<AvoidBreakInsideProps>`
  padding: 13px 0 10px 0;
  border-top: ${(props) => `1px solid ${props.theme.colors.grey[30]}`};

  &:last-child {
    border-bottom: ${(props) => `1px solid ${props.theme.colors.grey[30]}`};
  }

  ${(props) =>
    props.avoidBreakInside &&
    css`
      page-break-inside: avoid;
    `};
`;
