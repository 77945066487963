import Box from '@material-ui/core/Box';
import styled from 'styled-components';

export const QuestionContainer = styled(Box)`
  &:first-child {
    margin-top: 0;
  }
`;

export const FieldLabel = styled(Box)`
  color: ${(props) => props.theme.colors.grey[60]};
`;

export const FieldContainer = styled(Box)`
  &:last-child {
    margin-bottom: 0;
  }
`;

export const TextField = styled(Box)`
  color: ${(props) => props.theme.colors.grey[90]};
`;
