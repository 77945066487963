import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'html-react-parser';
import React, { useCallback } from 'react';

import { OptionWidth, RenderingFieldOption, RenderingInfoSupplement } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';
import {
  FieldProps,
  Icon,
  InfoSupplement,
  Label,
  RadioCard,
  SimpleRadio,
  SimpleRadioGroup,
  WarningText,
} from '@breathelife/ui-components';

import { translate } from '../../../Localization/Localizer';
import { StyleVariant } from '../../FieldGenerator/FieldGenerator';
import { TextWithSuperscriptHighlight, Title } from '../Checkbox/Styles';
import {
  ContentContainer,
  Extra,
  FullWidthLabel,
  OptionContainer,
  StyledGridItem,
  StyledRadioCardContainer,
} from './Styles';

type RadioGroupProps = Omit<FieldProps, 'onAnswerChange'> & {
  id: string;
  options: RenderingFieldOption[];
  required?: boolean;
  value?: string;
  optionWidth?: OptionWidth;
  onAnswerChange?: (answer: string) => void;
  onAnswerComplete?: (fieldId: string, answer: string, previousAnswer?: string) => void;
  styleVariant: StyleVariant;
  displayOnlySelected?: boolean;
  iconMap?: Record<string, string>;
  onInfoIconClick?: () => void;
  locale?: Language;
  renderingInfoSupplement?: RenderingInfoSupplement;
  infoSupplementImage?: { src: string; alt?: string };
};

function isOptionChecked(answer: string, optionId: string): boolean {
  return answer === optionId;
}

export function RadioGroup(props: RadioGroupProps): React.ReactElement {
  const {
    name,
    locale,
    options,
    title,
    subtitle,
    onAnswerChange,
    onAnswerComplete,
    renderingInfoSupplement,
    onInfoIconClick,
    infoSupplementImage,
    required,
  } = props;
  const showError = !!props.validationError;
  const validationMessageId = `${props.id}-error`;

  const requiredSuffix = required ? ' *' : '';
  const titleWithSuffix = title ? `${title}${requiredSuffix}` : null;

  const onChange = useCallback(
    (value: string) => {
      onAnswerChange?.(value);
      onAnswerComplete?.(name, value);
    },
    [name, onAnswerChange, onAnswerComplete]
  );

  if (props.styleVariant === StyleVariant.consumer) {
    const optionCards: React.ReactNode[] = [];
    const infoSupplements: React.ReactNode[] = [];
    options.forEach((option) => {
      const isChecked = isOptionChecked(props.value as string, option.id);
      optionCards.push(
        <RadioCard
          key={option.id}
          optionId={option.id}
          ariaLabel={option.text}
          groupName={props.id}
          checked={isChecked}
          onChange={onChange}
          suffix={option.iconName ? <Icon name={option.iconName} size='56px' /> : undefined}
          showErrorBorder={showError}
          disabled={props.disabled}
          marginless
        >
          {option.title ? <Title>{ReactHtmlParser(option.title)}</Title> : null}
          <TextWithSuperscriptHighlight selected={isChecked}>
            {ReactHtmlParser(option.text)}
          </TextWithSuperscriptHighlight>
        </RadioCard>
      );

      if (option.info) {
        const { title, text, iconNumber, modalOptions, image } = option.info;

        const imageSrc = image && props.iconMap?.[image.name];
        const imageInfo = imageSrc
          ? {
              src: imageSrc,
              alt: image?.alt,
            }
          : undefined;

        infoSupplements.push(
          <Box mt={1.5}>
            <InfoSupplement
              text={text}
              title={title}
              image={imageInfo}
              modalOptions={modalOptions}
              iconNumber={iconNumber}
              usePill
              onClick={props.onInfoIconClick}
            />
          </Box>
        );
      }
    });

    return (
      <React.Fragment>
        {titleWithSuffix && (
          <Box mb={1}>
            <Label htmlFor={props.id}>{ReactHtmlParser(titleWithSuffix)}</Label>
            {subtitle && (
              <FullWidthLabel htmlFor={props.id} grey={80}>
                {ReactHtmlParser(subtitle)}
              </FullWidthLabel>
            )}
          </Box>
        )}
        <ContentContainer>
          <OptionContainer>
            <StyledRadioCardContainer
              id={props.id}
              aria-errormessage={validationMessageId}
              optionWidth={props.optionWidth}
            >
              {optionCards}
            </StyledRadioCardContainer>
            {infoSupplements}
          </OptionContainer>
          {renderingInfoSupplement && (
            <Extra>
              <InfoSupplement
                title={renderingInfoSupplement.title}
                text={renderingInfoSupplement.text}
                image={infoSupplementImage}
                modalOptions={renderingInfoSupplement.modalOptions}
                onClick={onInfoIconClick}
              />
            </Extra>
          )}
        </ContentContainer>

        <Box component={WarningText} mt={2} role='alert' id={validationMessageId}>
          {showError && (props.validationError?.message || translate('validation.radioCheckbox', { locale }))}
        </Box>
      </React.Fragment>
    );
  }

  const fullLine = props.optionWidth === 'full';

  return (
    <React.Fragment>
      <SimpleRadioGroup label={title} subtitle={subtitle} name={props.name} onChange={onChange} value={props.value}>
        <Grid container spacing={1} direction={fullLine ? 'column' : 'row'}>
          {options.map((option) => {
            return (
              <StyledGridItem item xs={12} sm={fullLine ? 6 : 3} key={option.id}>
                <SimpleRadio
                  value={option.id}
                  label={option.text}
                  disabled={props.disabled}
                  hasError={!!props.validationError}
                />
              </StyledGridItem>
            );
          })}
        </Grid>
      </SimpleRadioGroup>
      {showError && (
        <Box component={WarningText} mt={2} role='alert' id={validationMessageId}>
          {props.validationError?.message || translate('validation.radioCheckbox', { locale })}
        </Box>
      )}
    </React.Fragment>
  );
}
