import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import ReactHtmlParser from 'html-react-parser';
import React from 'react';

import { FieldProps, SimpleCheckbox, WarningText } from '@breathelife/ui-components';

import { StyleVariant } from '../../FieldGenerator/FieldGenerator';
import { CheckboxFormControl, CheckboxLabel } from './Styles';

type BaseCheckboxFieldProps = Omit<FieldProps, 'onAnswerChange'> & {
  id: string;
  label: string;
  field?: any;
  inline?: boolean;
  required?: boolean;
  showError?: boolean;
  value?: boolean;
  onAnswerChange?: (answer: boolean) => void;
  onAnswerComplete?: (field: string, answer: boolean, previousAnswerBoolean?: boolean) => void;
  styleVariant: StyleVariant;
};

type CheckboxFieldProps = BaseCheckboxFieldProps & {
  isSingleCheckbox?: boolean;
};

export function CheckboxFieldMUI(props: CheckboxFieldProps): React.ReactElement {
  const labelText = props.title || props.label;
  if (props.styleVariant === StyleVariant.consumer) {
    return (
      <React.Fragment>
        <SimpleCheckbox
          id={props.id}
          label={labelText}
          checked={Boolean(props.value)}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            props.onAnswerChange && props.onAnswerChange(event.target.checked)
          }
        />
        {props.validationError && (
          <Box component={WarningText} mt={2}>
            {props.validationError.message}
          </Box>
        )}
      </React.Fragment>
    );
  }
  return (
    <React.Fragment>
      <CheckboxFormControl
        showError={!!props.showError}
        label={<CheckboxLabel disabled={props.disabled}>{ReactHtmlParser(labelText)}</CheckboxLabel>}
        data-testid={!props.isSingleCheckbox && props.id}
        checked={!!props.value}
        control={
          <Checkbox
            color='primary'
            name={props.name}
            checked={!!props.value}
            required={props.required}
            disabled={props.disabled}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              props.onAnswerChange && props.onAnswerChange(event.target.checked);
              props.onAnswerComplete && props.onAnswerComplete(props.name, event.target.checked, props.value);
            }}
          />
        }
      />
      {props.validationError && <WarningText>{props.validationError.message}</WarningText>}
    </React.Fragment>
  );
}

export function CheckboxField(props: BaseCheckboxFieldProps): React.ReactElement {
  return <CheckboxFieldMUI {...props} isSingleCheckbox={true} />;
}
