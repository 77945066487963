import FormControl, { FormControlProps } from '@material-ui/core/FormControl';
import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import styled, { css } from 'styled-components';

import { Label, RadioCardContainer } from '@breathelife/ui-components';

const proWarningBorder = css`
  ${(props: any) => `2px solid ${props.theme.colors.text.flashy} !important`}
`;

const proDefaultBorder = css`
  ${(props: any) => `1px solid ${props.theme.colors.grey[40]} !important`}
`;

export const RadioButtonFormControl = styled(FormControlLabel)<FormControlLabelProps & { showError: boolean }>`
  border: ${(props) => (props.showError ? proWarningBorder : proDefaultBorder)};
  margin: 12px 24px 0 0 !important;
  width: 285px;
  padding: 3px 0 3px 3px;
`;

export const StyledFormControl = styled(FormControl)<FormControlProps & { inline: boolean }>`
  flex-direction: ${(props) => (props.inline ? 'row !important' : 'column')};
`;

export const FullWidthLabel = styled(Label)`
  width: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
`;

export const OptionContainer = styled.div`
  display: flex;
  flex-grow: 1;
`;

export const Extra = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
`;

export const StyledRadioCardContainer = styled(RadioCardContainer)`
  width: 100%;
`;

export const StyledGridItem = styled(Grid)`
  max-width: 50% !important;
`;
