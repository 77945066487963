import Button from '@material-ui/core/Button';
import styled from 'styled-components';

import { StyleVariant } from '../../../../index';

type ButtonStyleProps = {
  $styleVariant: StyleVariant;
};

export const AddButton = styled(Button)<ButtonStyleProps>`
  && {
    margin-top: ${(props) => (props.$styleVariant === StyleVariant.pro ? '16px' : '60px')};
    text-transform: none;
    color: ${(props) => props.theme.colors.grey[70]};
    &:hover {
      color: ${(props) => props.theme.colors.primary.default} !important;
    }

    svg {
      #add-border {
        stroke: ${(props) => props.theme.colors.primary.default};
      }
      #add-plus {
        fill: ${(props) => props.theme.colors.primary.default};
      }
    }
  }
`;

export const RepeatTitle = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 15px 0 20px 0;
`;

export const RemoveButton = styled(Button)<ButtonStyleProps>`
  && {
    margin-top: ${(props) => (props.$styleVariant === StyleVariant.pro ? undefined : '20px')};
    color: ${(props) => props.theme.colors.grey[80]};
    text-align: left;
    text-transform: none;

    &:hover {
      color: ${(props) => props.theme.colors.grey[90]};
    }

    &:hover svg {
      g,
      path {
        stroke: ${(props) => props.theme.colors.grey[90]};
      }
    }
  }
`;
