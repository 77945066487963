import Box from '@material-ui/core/Box';
import ReactHtmlParser from 'html-react-parser';
import React from 'react';

import { FieldTypes, isRenderingOptionField, RenderingField } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import { formatTextFieldValue, generateCheckboxes, isUnansweredField } from '../../Helpers';
import { translate } from '../../Localization/Localizer';
import { FieldContainer, FieldLabel, TextField } from './Styles';
import { CheckboxGroup } from './checkbox/CheckboxGroup';

type FieldProps = {
  currency: string;
  field: RenderingField;
  locale: Language;
};

export function Field(props: FieldProps): React.ReactElement | null {
  const { currency, field, locale } = props;

  if (!field.visible || isUnansweredField(field)) return null;
  const optionalLabel = ` (${translate('validation.optional', { locale })})`;

  return (
    <FieldContainer my={2} width='100%' letterSpacing={-0.42} minHeight={32}>
      {field.title && (
        <FieldLabel fontSize={14} fontWeight='normal'>
          {ReactHtmlParser(field.title)}
        </FieldLabel>
      )}
      {field.text && (
        <FieldLabel fontSize={14} fontWeight='normal'>
          {ReactHtmlParser(field.text)}
        </FieldLabel>
      )}
      {field.label && (
        <FieldLabel fontSize={14} fontWeight='normal'>
          {ReactHtmlParser(field.label)}
          {field.optional && optionalLabel}
        </FieldLabel>
      )}
      <Box mt={0.5}>{FieldFactory(field, locale, currency)}</Box>
    </FieldContainer>
  );
}

function FieldFactory(field: RenderingField, locale: Language, currency: string): React.ReactElement {
  switch (field.type) {
    case FieldTypes.input:
    case FieldTypes.date:
    case FieldTypes.yearMonth:
    case FieldTypes.phone:
    case FieldTypes.textarea:
    case FieldTypes.number:
    case FieldTypes.currencyCard:
    case FieldTypes.autocomplete:
    case FieldTypes.money: {
      return <TextField fontSize={16}>{formatTextFieldValue(field.value, field.type, locale, currency)}</TextField>;
    }
    case FieldTypes.dropdown: {
      const checkboxes = generateCheckboxes(field, true);
      let fallbackValue: string | undefined = undefined;

      if (
        !checkboxes.length &&
        isRenderingOptionField(field) &&
        !field.options.map((option) => option.text).includes(field.value)
      ) {
        fallbackValue = field.value as string;
      }

      return <CheckboxGroup checkboxes={checkboxes} fallbackValue={fallbackValue} />;
    }
    case FieldTypes.button:
    case FieldTypes.radio:
    case FieldTypes.checkboxGroup:
    case FieldTypes.checkbox:
    case FieldTypes.agree: {
      return <CheckboxGroup checkboxes={generateCheckboxes(field)} />;
    }
    case FieldTypes.information:
      return <React.Fragment />;
    default:
      throw Error('Please specify a type of field supported by the field generator');
  }
}
