import React from 'react';

import { Checkbox, CheckboxData } from './Checkbox';
import { CheckboxGroupWrap } from './Styles';

type CheckboxGroupProps = {
  checkboxes: CheckboxData[];
  fallbackValue?: string;
};

export function CheckboxGroup(props: CheckboxGroupProps): React.ReactElement {
  const { checkboxes, fallbackValue } = props;
  const hasValidOptions = checkboxes.length > 0;

  return (
    <CheckboxGroupWrap fontSize={16} ml={hasValidOptions ? -1.5 : 0}>
      {hasValidOptions
        ? checkboxes.map((checkbox: CheckboxData, index: number) => (
            <Checkbox key={`${checkbox.label}-${index}`} checkbox={checkbox} />
          ))
        : fallbackValue}
    </CheckboxGroupWrap>
  );
}
