import Box from '@material-ui/core/Box';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import styled, { css } from 'styled-components';

export const CheckboxGroupWrap = styled(Box)`
  color: ${(props) => props.theme.colors.grey[90]};
`;

const checkboxStyle = css`
  height: 15px !important;
  width: 15px !important;
  vertical-align: top;
  margin: 2px 3px 0 -1px;
`;

export const CheckedIcon = styled(CheckBoxIcon)`
  ${checkboxStyle}
`;

export const UncheckedIcon = styled(CheckBoxOutlineBlankIcon)`
  ${checkboxStyle}
`;

export const Bold = styled.span`
  font-weight: 500;
`;
