import { Box, Grid, GridSize } from '@material-ui/core';
import React from 'react';

import { FieldTypes, isRenderingInformationField, RenderingField } from '@breathelife/questionnaire-engine';
import { FieldSizes, Language } from '@breathelife/types';
import {
  Information as InformationField,
  InputVariant,
  StatCard,
  TextInput as TextField,
} from '@breathelife/ui-components';

import { getFieldSize } from '../../Helpers/field';
import { getInputVariant } from '../../Helpers/inputVariant';
import { StyleVariant } from '../FieldGenerator/FieldGenerator';

type ReadOnlyFieldProps = {
  field: RenderingField;
  locale?: Language;
  isSingleField: boolean;
  styleVariant: StyleVariant;
};

export function ReadOnlyField(props: ReadOnlyFieldProps): React.ReactElement | null {
  const { field, locale, isSingleField, styleVariant } = props;
  if (!field.visible) return null;

  const inputVariant: InputVariant = getInputVariant(styleVariant);

  switch (field.type) {
    case FieldTypes.currencyCard:
      return (
        <Grid item xs={FieldSizes.full as GridSize} data-testid={field.id}>
          <Box mt={isSingleField ? 0.5 : 2} mb={0.5}>
            <StatCard
              title={field.title || ''}
              subtitle={field.value}
              info={field.info}
              isCurrency={true}
              locale={locale}
            />
          </Box>
        </Grid>
      );

    case FieldTypes.information:
      const variant = isRenderingInformationField(field) ? field.variant : undefined;
      return <InformationField text={field.text || ''} title={field.title || ''} locale={locale} variant={variant} />;

    case FieldTypes.input:
      const fieldProps = {
        id: field.id,
        name: field.id,
        ['data-testid']: field.nodeId,
        disabled: true,
        isReadOnly: true,
        value: field.value,
        title: field.title,
        subtitle: field.text,
        inputVariant,
      };
      const fieldSize = getFieldSize(field);
      return (
        <Grid item xs={fieldSize as GridSize} data-testid={field.id}>
          <TextField {...fieldProps} />
        </Grid>
      );

    // This is handled by the dynamic PDF package, and we don't render this field type with the field-generator
    case FieldTypes.signature:
      return null;

    default:
      throw new Error(`FieldType '${field.type}' not supported by ReadOnlyField`);
  }
}
