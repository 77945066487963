import Box from '@material-ui/core/Box';
import React, { useCallback } from 'react';

import {
  FieldTypes,
  isRenderingOptionField,
  RenderingField,
  RenderingQuestion,
} from '@breathelife/questionnaire-engine';
import { IconName, Language } from '@breathelife/types';
import { Text, Icon } from '@breathelife/ui-components';

import { formatTextFieldValue, generateCheckboxes, isUnansweredField, replaceHtmlTag } from '../../Helpers';
import { Button, FieldContainer, FieldLabel } from './Styles';
import { CheckboxGroup } from './checkbox/CheckboxGroup';

type FieldProps = {
  currency: string;
  field: RenderingField;
  locale: Language;
  onEditClick: (stepId: string) => void;
  stepId: string;
  question?: RenderingQuestion;
};

export function Field(props: FieldProps): React.ReactElement | null {
  const { currency, field, locale, stepId, onEditClick } = props;

  const onClick = useCallback(() => {
    return onEditClick(stepId);
  }, [onEditClick, stepId]);

  if (!field.visible || isUnansweredField(field)) return null;

  const fieldLabel = field.label || field.title;

  // This is handled by the dynamic PDF package, and we don't render this field type with the field-generator
  if (field.type === FieldTypes.signature) {
    return null;
  }

  return (
    <FieldContainer mb={3}>
      <Box display='flex' fontSize={13}>
        {!isCheckboxRenderedByChild(field.type) && (
          <Box pr={1.5} pt={0.5}>
            <Icon name={IconName.checkmark} size='12px' />
          </Box>
        )}

        <Box flex='auto'>
          {fieldLabel && (
            <FieldLabel grey={90} mb={1}>
              {replaceHtmlTag(fieldLabel, 'strong', 'span')}
            </FieldLabel>
          )}
          {field.text && <FieldLabel mb={1}>{replaceHtmlTag(field.text, 'strong', 'span')}</FieldLabel>}

          <Box display='flex' justifyContent='space-between'>
            <Box fontSize={16} pr={1} fontWeight={600}>
              {FieldFactory(field, locale, currency)}
            </Box>
            <Button onClick={onClick} title={stepId}>
              <Icon name={IconName.edit} size='12px' />
            </Button>
          </Box>
        </Box>
      </Box>
    </FieldContainer>
  );
}

function FieldFactory(field: RenderingField, locale: Language, currency: string): React.ReactElement {
  switch (field.type) {
    case FieldTypes.input:
    case FieldTypes.autocomplete:
    case FieldTypes.date:
    case FieldTypes.yearMonth:
    case FieldTypes.phone:
    case FieldTypes.textarea:
    case FieldTypes.number:
    case FieldTypes.currencyCard:
    case FieldTypes.money: {
      return <Text variant='copy'>{formatTextFieldValue(field.value, field.type, locale, currency)}</Text>;
    }
    case FieldTypes.dropdown: {
      const checkboxes = generateCheckboxes(field, true);
      let fallbackValue: string | undefined = undefined;

      if (
        !checkboxes.length &&
        isRenderingOptionField(field) &&
        !field.options.map((option) => option.text).includes(field.value)
      ) {
        fallbackValue = field.value as string;
      }

      return <CheckboxGroup checkboxes={checkboxes} fallbackValue={fallbackValue} fieldType={field.type} />;
    }
    case FieldTypes.button:
    case FieldTypes.radio:
    case FieldTypes.checkboxGroup:
    case FieldTypes.checkbox:
    case FieldTypes.agree: {
      return <CheckboxGroup checkboxes={generateCheckboxes(field)} fieldType={field.type} />;
    }
    case FieldTypes.information:
      return <React.Fragment />;
    default:
      throw Error('Please specify a type of field supported by the field generator');
  }
}

function isCheckboxRenderedByChild(fieldType: FieldTypes): boolean {
  const checkboxFieldTypes = [FieldTypes.checkboxGroup, FieldTypes.checkbox];
  return checkboxFieldTypes.includes(fieldType);
}
