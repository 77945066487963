import ReactHtmlParser from 'html-react-parser';
import React from 'react';

import { RenderingField, RenderingRepeatedQuestion, RepeatedIndices } from '@breathelife/questionnaire-engine';
import { Separator } from '@breathelife/ui-components';

import { StyleVariant } from '../../../FieldGenerator/FieldGenerator';
import { QuestionText, RepeatableQuestionTitle } from '../Styles';
import { AddButton, RemoveButton, RepeatTitle } from './Styles';

function AddIcon(): React.ReactElement {
  return (
    <svg height='22' viewBox='0 0 22 22' width='22' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd'>
        <path
          id='add-border'
          d='m11.0084229 21.4986572c5.7989898 0 10.5-4.7010101 10.5-10.5 0-5.79898985-4.7010102-10.49999997-10.5-10.49999997-5.79898992 0-10.50000005 4.70101012-10.50000005 10.49999997 0 5.7989899 4.70101013 10.5 10.50000005 10.5z'
          stroke='#999'
        />
        <g fill='#999' id='add-plus'>
          <rect height='12' rx='1' width='2' x='10' y='5' />
          <path
            d='m11 5c.5522847 0 1 .44771525 1 1v10c0 .5522847-.4477153 1-1 1-.5522847 0-1-.4477153-1-1v-10c0-.55228475.4477153-1 1-1z'
            transform='matrix(0 1 -1 0 22 0)'
          />
        </g>
      </g>
    </svg>
  );
}
function DeleteIcon(): React.ReactElement {
  return (
    <svg height='22' viewBox='0 0 22 22' width='22' xmlns='http://www.w3.org/2000/svg'>
      <g fill='none' fillRule='evenodd' stroke='#999'>
        <path d='m11.0084229 21.4986572c5.7989898 0 10.5-4.7010101 10.5-10.5 0-5.79898985-4.7010102-10.49999997-10.5-10.49999997-5.79898992 0-10.50000005 4.70101012-10.50000005 10.49999997 0 5.7989899 4.70101013 10.5 10.50000005 10.5z' />
        <g fill='#999' transform='matrix(.70710678 .70710678 -.70710678 .70710678 11 2.514719)'>
          <rect height='11' rx='.5' width='1' x='5.5' y='.5' />
          <path
            d='m6 .5c-.27614237 0-.5.22385763-.5.5v10c0 .2761424.22385763.5.5.5s.5-.2238576.5-.5v-10c0-.27614237-.22385763-.5-.5-.5z'
            transform='matrix(0 1 -1 0 12 0)'
          />
        </g>
      </g>
    </svg>
  );
}

type OnAnswerCompleteFunction = (fieldId: string, answer: any, previousAnswer: any) => void;

type Props = {
  question: RenderingRepeatedQuestion;
  onQuestionAdd: () => void;
  onQuestionDelete: () => void;
  onAnswerComplete: OnAnswerCompleteFunction;
  repeatedIndices: RepeatedIndices;
  renderContent: (
    fields: RenderingField[],
    onAnswerComplete: (fieldId: string, answer: any, previousAnswer: any, repeatedQuestionIndex?: number) => void,
    repeatedIndices: RepeatedIndices
  ) => React.ReactNode;
  styleVariant: StyleVariant;
};

export function RepeatableQuestionContent(props: Props): React.ReactElement {
  const {
    id,
    nodeId,
    title,
    text,
    fields,
    showRemoveQuestionButton,
    showAddQuestionButton,
    addQuestionButtonText,
    removeQuestionButtonText,
  } = props.question;
  const { repeatedIndices } = props;

  const onAnswerComplete = onAnswerCompleteWithQuestionIndex(props.onAnswerComplete, repeatedIndices[nodeId]);

  return (
    <React.Fragment key={id}>
      <RepeatTitle>
        <RepeatableQuestionTitle isFirst={true} styleVariant={props.styleVariant}>
          {title}
        </RepeatableQuestionTitle>

        {showRemoveQuestionButton && (
          <RemoveButton
            onClick={props.onQuestionDelete}
            data-testid={`remove-${id}`}
            $styleVariant={props.styleVariant}
            startIcon={<DeleteIcon />}
          >
            {removeQuestionButtonText}
          </RemoveButton>
        )}
      </RepeatTitle>

      {text && <QuestionText styleVariant={props.styleVariant}>{ReactHtmlParser(text)}</QuestionText>}

      {props.renderContent(fields, onAnswerComplete, repeatedIndices)}
      {!showAddQuestionButton && <Separator />}
      {showAddQuestionButton && (
        <AddButton
          color='primary'
          onClick={props.onQuestionAdd}
          data-testid={`add-${id}`}
          $styleVariant={props.styleVariant}
          startIcon={<AddIcon />}
        >
          {addQuestionButtonText}
        </AddButton>
      )}
    </React.Fragment>
  );
}

function onAnswerCompleteWithQuestionIndex(
  onAnswerComplete: (fieldId: string, answer: any, previousAnswer: any, repeatedQuestionIndex?: number) => void,
  repeatedQuestionIndex?: number
): OnAnswerCompleteFunction {
  return (fieldId: string, answer: any, previousAnswer: any) => {
    onAnswerComplete && onAnswerComplete(fieldId, answer, previousAnswer, repeatedQuestionIndex);
  };
}
