import React from 'react';

import {
  getRepeatedQuestionIndex,
  OnAnswerChange,
  RenderingField,
  RenderingRepeatedQuestion,
  RepeatedIndices,
} from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';

import { StyleVariant } from '../../../FieldGenerator/FieldGenerator';
import { QuestionContent } from '../QuestionContent';
import { Container } from '../Styles';
import { RepeatableQuestionContent } from './RepeatableQuestionContent';

type RepeatableQuestionProps = {
  question: RenderingRepeatedQuestion;
  onAnswerChange: OnAnswerChange;
  onAnswerComplete: (fieldId: string, answer: any, previousAnswer: any) => void;
  styleVariant: StyleVariant;
  repeatedIndices: RepeatedIndices;
  locale?: Language;
  iconMap?: Record<string, string>;
};

export function RepeatableQuestion(props: RepeatableQuestionProps): React.ReactElement {
  const { question, onAnswerChange, onAnswerComplete, styleVariant, locale, iconMap, repeatedIndices } = props;
  const repeatedQuestionIndex: number = getRepeatedQuestionIndex(question);

  function addRepeatedQuestion(nodeId: string, indices?: RepeatedIndices): void {
    onAnswerChange(nodeId, {}, question.effects, indices);
  }

  function deleteRepeatedQuestion(nodeId: string, indices?: RepeatedIndices): void {
    onAnswerChange(nodeId, undefined, question.effects, indices);
  }

  return (
    <Container
      styleVariant={styleVariant}
      key={question.surrogateId ?? question.id}
      data-testid={'repeatable_question'}
    >
      <RepeatableQuestionContent
        question={question}
        repeatedIndices={repeatedIndices}
        onQuestionAdd={() => {
          addRepeatedQuestion(question.nodeId, {
            ...repeatedIndices,
            [question.nodeId]: repeatedQuestionIndex + 1,
          });
        }}
        onQuestionDelete={() => {
          const repeatedIndicesWithQuestion: RepeatedIndices = {
            ...repeatedIndices,
            [question.nodeId]: repeatedQuestionIndex,
          };
          deleteRepeatedQuestion(question.nodeId, repeatedIndicesWithQuestion);
        }}
        onAnswerComplete={onAnswerComplete}
        renderContent={renderRepeatableQuestionContent(onAnswerChange, styleVariant, locale, iconMap)}
        styleVariant={styleVariant}
      />
    </Container>
  );
}

function renderRepeatableQuestionContent(
  onAnswerChange: OnAnswerChange,
  styleVariant: StyleVariant,
  locale?: Language,
  iconMap?: Record<string, string>
) {
  return (
    fields: RenderingField[],
    onAnswerComplete: (fieldId: string, answer: any, previousAnswer: any, repeatedQuestionIndex?: number) => void,
    repeatedIndices: RepeatedIndices
  ) => (
    <QuestionContent
      fields={fields}
      onAnswerChange={onAnswerChange}
      onAnswerComplete={onAnswerComplete}
      styleVariant={styleVariant}
      repeatedIndices={repeatedIndices}
      locale={locale}
      iconMap={iconMap}
    />
  );
}
