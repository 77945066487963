import Box from '@material-ui/core/Box';
import ReactHtmlParser from 'html-react-parser';
import React from 'react';

import { CheckboxCard, FieldProps, WarningText } from '@breathelife/ui-components';

import { translate } from '../../../Localization/Localizer';
import { StyleVariant } from '../../FieldGenerator/FieldGenerator';
import { QuestionText } from '../Question/Styles';

type Props = FieldProps & {
  id: string;
  value?: boolean;
  title: string;
  consentText: string;
  styleVariant: StyleVariant;
  showError?: boolean;
};

export function CheckboxAgree(props: Props): React.ReactElement {
  return (
    <Box>
      <QuestionText styleVariant={props.styleVariant}>{ReactHtmlParser(props.consentText)}</QuestionText>
      <CheckboxCard
        marginless
        optionId={props.id}
        groupName='agree-group'
        onChange={(optionId: string, checked: boolean) => props.onAnswerChange(checked)}
        checked={!!props.value}
        showErrorBorder={props.showError}
        disabled={props.disabled}
      >
        {<b>{ReactHtmlParser(props.title) || translate('agree.checkbox')}</b>}
      </CheckboxCard>
      <Box component={WarningText} mt={2} role='alert' id={`${props.id}-error`}>
        {props.showError && (props.validationError?.message || translate('validation.agreeField'))}
      </Box>
    </Box>
  );
}
