import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import ReactHtmlParser from 'html-react-parser';
import React from 'react';

import { RenderingField } from '@breathelife/questionnaire-engine';
import { DynamicPdfFieldProps } from '@breathelife/types';
import { PdfFieldText } from '@breathelife/ui-components';

import { BaseFieldProps } from '../FieldGenerator/FieldGenerator';
import { Field } from './Field';

export type DynamicPdfQuestionProps = BaseFieldProps & { fieldOptions: DynamicPdfFieldProps };

export function DynamicPdfQuestion(props: DynamicPdfQuestionProps): React.ReactElement | null {
  const { currency, locale, question, fieldOptions } = props;

  return (
    <React.Fragment>
      {question.title && (
        <Box mb={2} mt={-1}>
          <PdfFieldText>{ReactHtmlParser(question.title)}</PdfFieldText>
        </Box>
      )}

      <Grid container spacing={3} alignItems={fieldOptions.displayAll ? 'flex-start' : 'flex-end'}>
        {question.fields.map((field: RenderingField) => (
          <React.Fragment key={field.id}>
            <Field currency={currency} field={field} locale={locale} fieldOptions={fieldOptions} />
          </React.Fragment>
        ))}
      </Grid>
    </React.Fragment>
  );
}
