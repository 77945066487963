import Box from '@material-ui/core/Box';
import React from 'react';

import { RenderingField } from '@breathelife/questionnaire-engine';
import { Text } from '@breathelife/ui-components';

import { hasValidChildFields, replaceHtmlTag } from '../../Helpers';
import { BaseFieldProps } from '../FieldGenerator/FieldGenerator';
import { Field } from './Field';
import { QuestionContainer } from './Styles';

export type SummaryQuestionProps = BaseFieldProps & {
  stepId: string;
  onEditClick: (stepId: string) => void;
};

export function SummaryQuestion(props: SummaryQuestionProps): React.ReactElement | null {
  const { question, stepId } = props;

  if (!question.visible || !hasValidChildFields(question)) return null;

  return (
    <QuestionContainer mb={3}>
      <Box>
        {question.title && (
          <Box my={3}>
            <Text variant='small-body' grey={90}>
              {replaceHtmlTag(question.title, 'strong', 'span')}
            </Text>
          </Box>
        )}
        {question.fields.map((field: RenderingField) => (
          <Field {...props} key={field.id} field={field} stepId={stepId} />
        ))}
      </Box>
    </QuestionContainer>
  );
}
