import HTMLReactParser, { domToReact, HTMLReactParserOptions } from 'html-react-parser';
import React from 'react';

import {
  FieldTypes,
  RenderingField,
  RenderingFieldOption,
  RenderingOptionField,
  RenderingQuestion,
} from '@breathelife/questionnaire-engine';
import { FieldSizes } from '@breathelife/types';

export function isUnansweredField(field: RenderingField): boolean {
  return field.value === '' || typeof field.value === 'undefined';
}

export const DefaultFieldSizesMap: { [key in FieldTypes]?: FieldSizes } = {
  [FieldTypes.agree]: FieldSizes.full,
  [FieldTypes.autocomplete]: FieldSizes.full,
  [FieldTypes.button]: FieldSizes.full,
  [FieldTypes.checkbox]: FieldSizes.full,
  [FieldTypes.checkboxGroup]: FieldSizes.full,
  [FieldTypes.currencyCard]: FieldSizes.full,
  [FieldTypes.date]: FieldSizes.half,
  [FieldTypes.yearMonth]: FieldSizes.half,
  [FieldTypes.dropdown]: FieldSizes.full,
  [FieldTypes.information]: FieldSizes.full,
  [FieldTypes.input]: FieldSizes.full,
  [FieldTypes.money]: FieldSizes.half,
  [FieldTypes.number]: FieldSizes.third,
  [FieldTypes.phone]: FieldSizes.twoThirds,
  [FieldTypes.radio]: FieldSizes.full,
  [FieldTypes.textarea]: FieldSizes.full,
};

export function getFieldSize(field: RenderingField): FieldSizes {
  return field.layout?.size ?? DefaultFieldSizesMap?.[field.type] ?? FieldSizes.full;
}

export function shouldForceNewLine(field: RenderingField): boolean {
  if (typeof field.layout?.forceNewLine !== 'undefined') return !!field.layout.forceNewLine;
  if (typeof field.layout?.size !== 'undefined') return false;

  const fieldSize = getFieldSize(field);
  return fieldSize !== FieldSizes.full;
}

export function getVisibleOptions(field: RenderingOptionField): RenderingFieldOption[] {
  return field.options.filter((option) => option.visible && (!field.displayOnlySelected || option.id === field.value));
}

// Field.text may possibly be deprecated in the future
export function getFieldLabel(field: RenderingField): string {
  return (field.title || field.label) ?? '';
}

export function hasValidChildFields(question: RenderingQuestion): boolean {
  const validFields = question.fields.filter((field) => !field.visible || !isUnansweredField(field));
  return validFields.length > 0;
}

export function replaceHtmlTag(html: string, element: string, newElement: string): JSX.Element | JSX.Element[] {
  const options: HTMLReactParserOptions = {
    replace: (node) => {
      if (!node) return;

      if (node.type === 'tag' && node.name === element) {
        return React.createElement(newElement, {}, domToReact(node.children));
      }
    },
  };

  return HTMLReactParser(html, options);
}
