import ReactHtmlParser from 'html-react-parser';
import React from 'react';

import { FieldTypes, RenderingField, RenderingQuestion } from '@breathelife/questionnaire-engine';

import { isUnansweredField } from '../../Helpers';
import { BaseFieldProps } from '../FieldGenerator/FieldGenerator';
import { Field } from './Field';
import { Label, QuestionContainer } from './Styles';

export type PdfQuestionProps = BaseFieldProps & {
  avoidBreaks: boolean;
};

function isQuestionHidden(question: RenderingQuestion): boolean {
  if (!question.visible) {
    return true;
  }

  if (question.showInPdfWithoutFields) {
    return false;
  }

  const fieldsDoNotExistOrAreAllUnanswered =
    !question.fields.length ||
    question.fields.every((field) => isUnansweredField(field) && field.type !== FieldTypes.information);
  if (fieldsDoNotExistOrAreAllUnanswered) {
    return true;
  }

  return false;
}

export function PdfQuestion(props: PdfQuestionProps): React.ReactElement | null {
  const { avoidBreaks, currency, locale, question } = props;
  if (isQuestionHidden(question)) return null;
  return (
    <QuestionContainer avoidBreakInside={avoidBreaks}>
      {question.title && <Label>{ReactHtmlParser(question.title)}</Label>}
      {question.text && <Label data-testid={`question-text-${question.id}`}>{ReactHtmlParser(question.text)}</Label>}
      {question.fields.map((field: RenderingField) => (
        <Field key={field.id} currency={currency} field={field} locale={locale} />
      ))}
    </QuestionContainer>
  );
}
