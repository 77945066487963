import FormControlLabel, { FormControlLabelProps } from '@material-ui/core/FormControlLabel';
import React from 'react';
import styled, { css } from 'styled-components';

import { Text } from '@breathelife/ui-components';

function FormControlLabelWithRest({
  showError,
  ...rest
}: FormControlLabelProps & {
  showError: boolean;
}): React.ReactElement {
  return <FormControlLabel {...rest} />;
}

export const CheckboxFormControl = styled(FormControlLabelWithRest)`
  ${(props) => {
    const { showError } = props;
    return css`
      margin: 10px 20px 0 0 !important;
      width: 100%;
      font-weight: ${(props) => props.theme.fonts.weight.medium};
      padding: 3px 0 3px 3px;
      border: ${(props) => showError && `1px solid ${props.theme.colors.error}`};
      background-color: ${(props) => props.theme.colors.grey[20]};
      border-radius: 4px;
    `;
  }}
`;

export const CheckboxLabel = styled.span<{ disabled?: boolean }>`
  &&& {
    color: ${(props) => props.theme.colors.grey[props.disabled ? 60 : 90]};
    font-size: ${(props) => props.theme.fonts.size.body};
    font-weight: ${(props) => props.theme.fonts.weight.regular};
  }
`;

export const Title = styled(Text)`
  font-weight: 500;
  margin-bottom: 4px;
  color: ${(props) => props.theme.colors.grey[90]};
`;

export const TextWithSuperscriptHighlight = styled(Text)<{ selected?: boolean }>`
  &&&&& {
    margin-top: 0;
    color: ${({ theme, selected }) =>
      selected ? theme.components.RadioCard.selected.color : theme.components.RadioCard.color};

    sup {
      color: ${({ theme, selected }) =>
        selected ? theme.components.RadioCard.selected.color : theme.components.RadioCard.color};
      font-weight: bold;
      font-size: 10px;
    }
  }
`;
