import { isRenderingOptionField, RenderingField, RenderingFieldOption } from '@breathelife/questionnaire-engine';

import { translate } from '../Localization/Localizer';
import { normalizeAsArray } from './normalize';

export const MAX_DISPLAYED_CHECKBOX_OPTIONS = 20;

export type CheckboxData = { label: string; checked: boolean };

export function generateCheckboxes(field: RenderingField, onlyChecked?: boolean): CheckboxData[] {
  if (typeof field.value === 'boolean') {
    return [
      { label: translate('yes'), checked: field.value },
      { label: translate('no'), checked: !field.value },
    ];
  }

  if (isRenderingOptionField(field)) {
    const checkedOptions: string[] = normalizeAsArray(field.value);
    const visibleOptions: RenderingFieldOption[] = field.options.filter(
      (option: RenderingFieldOption) => option.visible
    );
    let checkboxes: CheckboxData[] = visibleOptions.map((option: RenderingFieldOption) => ({
      title: option.title,
      label: option.text,
      checked: checkedOptions.includes(option.id),
    }));

    if (onlyChecked || checkboxes.length > MAX_DISPLAYED_CHECKBOX_OPTIONS) {
      // If there are too many options, don't display them all, just selected values
      checkboxes = checkboxes.filter((checkbox: CheckboxData) => checkbox.checked);
    }

    return checkboxes;
  }
  return [];
}
