import Box from '@material-ui/core/Box';
import ReactHtmlParser from 'html-react-parser';
import React from 'react';

import { FieldTypes } from '@breathelife/questionnaire-engine';
import { IconName } from '@breathelife/types';
import { Text, Icon } from '@breathelife/ui-components';

import { Bold, CheckboxGroupWrap, CheckboxWrap } from './Styles';

type CheckboxData = { title?: string; label: string; checked: boolean };

type CheckboxGroupProps = {
  checkboxes: CheckboxData[];
  fallbackValue?: string;
  fieldType: FieldTypes;
};

export function CheckboxGroup(props: CheckboxGroupProps): React.ReactElement {
  const { checkboxes, fallbackValue, fieldType } = props;
  const hasValidOptions = checkboxes.length > 0;

  const isCheckboxGroup = fieldType === FieldTypes.checkboxGroup;
  const checkedFields = isCheckboxGroup ? checkboxes : checkboxes.filter((checkbox) => checkbox.checked === true);

  return (
    <CheckboxGroupWrap>
      {hasValidOptions
        ? checkedFields.map((checkbox: CheckboxData, index: number) => (
            <Checkbox key={`${checkbox.label}-${index}`} checkbox={checkbox} displayCheckbox={isCheckboxGroup} />
          ))
        : fallbackValue}
    </CheckboxGroupWrap>
  );
}

function Checkbox(props: { checkbox: CheckboxData; displayCheckbox: boolean }): React.ReactElement {
  const { checkbox, displayCheckbox } = props;

  return (
    <CheckboxWrap display='flex' mb={1.5}>
      {displayCheckbox && (
        <Box pr={1.5} pt={0.5}>
          <Icon name={checkbox.checked ? IconName.checkmark : IconName.close} size='12px' />
        </Box>
      )}
      <Text variant='copy'>
        {checkbox.title && <Bold>{ReactHtmlParser(checkbox.title)}:</Bold>} {ReactHtmlParser(checkbox.label)}
      </Text>
    </CheckboxWrap>
  );
}
