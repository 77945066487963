import Box from '@material-ui/core/Box';
import ReactHtmlParser from 'html-react-parser';
import _ from 'lodash';
import React from 'react';

import { OptionWidth, RenderingFieldOption } from '@breathelife/questionnaire-engine';
import { Language } from '@breathelife/types';
import {
  CheckboxCard,
  CheckboxCardContainer,
  Container,
  FieldProps,
  InfoSupplement,
  Label,
  WarningText,
  Icon,
} from '@breathelife/ui-components';

import { translate } from '../../../Localization/Localizer';
import { StyleVariant } from '../../FieldGenerator/FieldGenerator';
import { CheckboxFieldMUI } from './Checkbox';
import { TextWithSuperscriptHighlight, Title } from './Styles';

type CheckboxFieldProps = Omit<FieldProps, 'onAnswerChange'> & {
  id: string;
  options: RenderingFieldOption[];
  required?: boolean;
  value?: string[];
  optionWidth?: OptionWidth;
  onAnswerChange?: (answer: string[]) => void;
  onAnswerComplete?: (field: string, answer?: boolean, previousAnswer?: boolean) => void;
  styleVariant: StyleVariant;
  iconMap?: Record<string, string>;
  onInfoIconClick?: () => void;
  locale?: Language;
};

function computeAnswerAfterChange(originalAnswer: string[], optionId: string, answer: boolean): string[] {
  if (answer) {
    return _.union(originalAnswer, [optionId]);
  }
  return _.remove(originalAnswer, (e) => e !== optionId);
}

function isOptionChecked(groupAnswers: unknown, optionId: string): boolean {
  return Array.isArray(groupAnswers) && groupAnswers.includes(optionId);
}

export function CheckboxGroup(props: CheckboxFieldProps): React.ReactElement {
  const { locale, subtitle } = props;
  const showError = !!props.validationError;

  if (props.styleVariant === StyleVariant.consumer) {
    const validationMessageId = `${props.id}-error`;
    const optionCards: React.ReactNode[] = [];
    const infoSupplements: React.ReactNode[] = [];

    props.options.forEach((option: RenderingFieldOption) => {
      const isChecked = isOptionChecked(props.value, option.id);
      optionCards.push(
        <CheckboxCard
          key={option.id}
          optionId={option.id}
          ariaLabel={option.text}
          groupName={props.id}
          checked={isChecked}
          onChange={(optionId: string, checked: boolean) =>
            props.onAnswerChange &&
            props.onAnswerChange(computeAnswerAfterChange(props.value as string[], optionId, checked))
          }
          suffix={option.iconName ? <Icon name={option.iconName} size='56px' /> : undefined}
          showErrorBorder={showError}
          disabled={props.disabled}
          marginless
        >
          {option.title && <Title>{ReactHtmlParser(option.title)}</Title>}
          <TextWithSuperscriptHighlight selected={isChecked}>
            {ReactHtmlParser(option.text)}
          </TextWithSuperscriptHighlight>
        </CheckboxCard>
      );

      if (option.info) {
        const { title, text, iconNumber, modalOptions, image } = option.info;

        const imageSrc = image && props.iconMap?.[image.name];
        const imageInfo = imageSrc
          ? {
              src: imageSrc,
              alt: image?.alt,
            }
          : undefined;

        infoSupplements.push(
          <Box mt={1.5}>
            <InfoSupplement
              text={text}
              title={title}
              image={imageInfo}
              modalOptions={modalOptions}
              iconNumber={iconNumber}
              usePill
              onClick={props.onInfoIconClick}
            />
          </Box>
        );
      }
    });

    return (
      <React.Fragment>
        {props.title && (
          <Box mb={1}>
            <Label htmlFor={props.id}>{ReactHtmlParser(props.title)}</Label>
          </Box>
        )}
        {subtitle && (
          <Box mb={1}>
            <Label htmlFor={props.id} grey={80}>
              {ReactHtmlParser(subtitle)}
            </Label>
          </Box>
        )}
        <CheckboxCardContainer id={props.id} aria-errormessage={validationMessageId} optionWidth={props.optionWidth}>
          {optionCards}
        </CheckboxCardContainer>
        {infoSupplements}
        <Box component={WarningText} mt={2} role='alert' id={validationMessageId}>
          {showError && (props.validationError?.message || translate('validation.radioCheckbox', { locale }))}
        </Box>
      </React.Fragment>
    );
  }

  return (
    <Container error={showError}>
      {props.title && (
        <Box mb={1} whiteSpace={props.optionWidth === 'full' ? 'normal' : 'noWrap'}>
          <Label htmlFor={props.id}>{ReactHtmlParser(props.title)}</Label>
        </Box>
      )}
      {subtitle && (
        <Box mb={1} whiteSpace={props.optionWidth === 'full' ? 'normal' : 'noWrap'}>
          <Label htmlFor={props.id} grey={80}>
            {ReactHtmlParser(subtitle)}
          </Label>
        </Box>
      )}
      {props.options.map((option: RenderingFieldOption) => {
        if (!option.visible) return null;

        return (
          <CheckboxFieldMUI
            id={`${props.id}_${option.id}`}
            key={option.id}
            name={option.id}
            label={option.text}
            required={props.required}
            disabled={props.disabled}
            showError={showError}
            value={isOptionChecked(props.value as string[], option.id)}
            styleVariant={props.styleVariant}
            onAnswerChange={(answer: any) => {
              props.onAnswerChange &&
                props.onAnswerChange(computeAnswerAfterChange(props.value as string[], option.id, answer));
            }}
            onAnswerComplete={(optionId: string, answer?: boolean, previousAnswer?: boolean) => {
              props.onAnswerComplete && props.onAnswerComplete(props.name, answer, previousAnswer);
            }}
          />
        );
      })}
      {showError && (
        <WarningText>{props.validationError?.message || translate('validation.radioCheckbox', { locale })}</WarningText>
      )}
    </Container>
  );
}
